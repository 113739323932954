// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
  'use strict';

  var objDiv;
  var strColor;
  var objFlag;

  // reference to img element used to check if images are disabled
  objFlag = document.getElementById('flag');
  if (objFlag === null) {
    return;
  }

  // Create a test div
  objDiv = document.createElement('div');

  // Set its color style to something unusual
  objDiv.style.color = 'rgb(31,41,59)';

  // Attach to body so we can inspect it
  document.body.appendChild(objDiv);

  // Use standard means if available, otherwise use the IE methods

  strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

  // get rid of extra spaces in result
  // strColor = strColor.replace(/ /g,"");

  // Delete the test DIV

  document.body.removeChild(objDiv);

  // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
  // Use .offsetwidth and .readyState (for IE) to check if images are enabled
  // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

  if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

    var objHead = document.getElementsByTagName('head');
    var objCSS = objHead[0].appendChild(document.createElement('link'));
    objCSS.rel = 'stylesheet';
    objCSS.href = '/media/css/alt.css';
    // objCSS.type = 'text/css';
  }
}

// Safely initiate foundation now
$(document).foundation();

// the rest of your code

$(window).load(function() {
  'use strict';

  // accessible image sprites
  HCTest();
});

// mobile-nav
$('.menu-trigger').click(
  function() {
    if (!$('.main-nav').hasClass('open')) {
      $('.main-nav-container')
        .addClass('is-visible')
        .removeClass('is-hidden');
      $('.main-nav').addClass('open');
    } else {
      $('.main-nav-container')
        .addClass('is-hidden')
        .removeClass('is-visible');
      $('.main-nav').removeClass('open');
    }
    return false;
  }
);

$(document).ready(function() {

// -----------------
  // Smooth scroll top
  // -----------------

  if (window.matchMedia('(min-width: 64em)').matches) {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 400) {
      $(".top").fadeIn();
    } else {
      $(".top").fadeOut();
    }
  });
  $(function() {
    //$('a[name]').addClass('anchor_offset');
    /*en cas de menú sticky, alçada àncores. Afegir css:
    .offset:before {
      content: " ";
      display: block;
      height: 120px; en funció l'alçada del menú sticky
      margin-top: -120px; en funció l'alçada del menú sticky
      visibility: hidden;
      }*/
    $('a[href*="#"]:not([href="#"],[href="#menu"])').click(function(e) {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        e.preventDefault();
        history.pushState({}, "", this.href);
        $('html, body').animate({
        scrollTop: target.offset().top
        }, 1000);
      return false;
      }
    }
    });
  });
  }

  //al validar formulari fer scroll tingui en compte sticky header
  $("input,textarea").focus(function(){
    var elementOffset = $(this).offset().top,
    scrollTop     = $(window).scrollTop(),
    distance      = (elementOffset - scrollTop);
    var label = $(this).closest('div').find('label');
    //modicar classes depent de projecte
    var addSpace = $('.main-header.sticky').height() + label.height() + 10 - distance;

    if(addSpace > 0)
    {
      var y = $(window).scrollTop();
      $(window).scrollTop(y-addSpace);
    }
  });

});
